import pdf from "./sample.pdf";
import React, { useEffect } from "react";

const FlipBook = () => {
  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };
    const loadCSS = (href) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      document.head.appendChild(link);
    };


    // Load jQuery first
    loadScript("https://code.jquery.com/jquery-3.6.0.min.js", () => {
      loadCSS("/assets/css/dflip.css");

      // Load DFlip after jQuery
      loadScript("/assets/js/dflip.min.js", () => {

        document.getElementById("button_pdf")?.addEventListener("click", () => {
          const flipbookPdf = new window.DFlip({
            source: "/assets/sample.pdf", // Make sure the path is correct
            webgl: false,
            height: 800,
            element: document.getElementById("button_pdf"),
          });
          flipbookPdf.load();
        });
      });
    });
  }, []);

  return (
    <>
      <div className="test">
        <div className="_df_book" source={pdf}></div>
      </div>
    </>
  );
};

export default FlipBook;
